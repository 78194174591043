import React, { useEffect } from 'react';
import "./ReferrersList.scss"
import { useSelector } from 'react-redux';
import { getProfileReferres, openReferrerFormModal, selectIsLoadingReferrer, selectReferrers, updateSelectedReferrer } from '../../store/slices/app';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import _ from 'lodash';

interface DataType {
    key: React.Key;
    firstname: string;
    lastname: string;
  }

const ReferrersList: React.FC = () => {

    const referrers = useSelector(selectReferrers)
    const isLoadingReferrer = useSelector(selectIsLoadingReferrer)
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        dispatch(getProfileReferres({}))
    }

    const refreshData = () => {
        fetchData()
    }

    const openReferrerForm = () => {
        dispatch(updateSelectedReferrer({
            firstname: "",
            lastname: "",
        }))
        dispatch(openReferrerFormModal())
    }

    const selectReferrer = (referrerData: any) => {
        dispatch(updateSelectedReferrer(referrerData))
        dispatch(openReferrerFormModal())
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (_, record) => (
                <a onClick={() => selectReferrer(record)}>
                    {record.firstname} {record.lastname}
                </a>
            )
        },
        {
            title: 'Public',
            dataIndex: 'is_public',
            render: (text) => text ? "Yes" : "-"
        },
        {
            title: 'Location',
            dataIndex: 'projects',
            render: (list) => list?.map((item: { title: any; }) => item.title)?.join(", ")
        }
    ];

    const data = () => {
        let data = referrers?.map(item => {
            return {
                ...item,
                key: item.id,
                lastnameLC: item.lastname.toLowerCase(),
                firstnameLC: item.firstname.toLowerCase(),
            }
        })
        data = _.sortBy(data, ['lastnameLC', 'firstnameLC']);
        return data
    }

    return (
        <div className='referrers-list'>
            <div>
                <Button onClick={refreshData} disabled={isLoadingReferrer}>Refresh</Button>
                <Button onClick={openReferrerForm} disabled={isLoadingReferrer}>New</Button>
            </div>
            <Table
                // rowSelection={{
                //   type: selectionType,
                //   ...rowSelection,
                // }}
                pagination={{defaultPageSize: 50}}
                columns={columns}
                dataSource={data()}
            />
      </div>
    );
};

export default ReferrersList;