import Button, { ButtonProps } from "../Button/Button";

const SaveButton = ({ loading, disabled }: ButtonProps) => {
    return (
        <Button
            loading={loading}
            data-testid="form-button"
            className="button"
            htmlType="submit"
            type="primary"
            shape='round'
            size="large"
            disabled={disabled}
        >
            Save
        </Button>
    )
};

export default SaveButton;
