import { useEffect } from "react";

import Input from "../../ui/Input/Input";
import Col from "../../ui/Col/Col";
import Row from "../../ui/Row/Row";
import Form from "../Form";
import {  Modal } from "antd";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import SaveButton from "../../ui/SaveButton/SaveButton";
import { getProfileId, getProfileProjects, getProjects } from "../../store/slices/user";

const { Item } = Form;

export interface IVisitorInFormProps {
    id?: number;
    email?: string;
    firstname?: string;
    lastname?: string;
    company?: string;
    referrer_id?: string;
    privacy_check?: string;
    visitor_id?: string;
    profile?: any;
    projects?: any;
}

export interface VisitorInFormProps {
    errors?: any;
    handleSubmit?: (values: any) => void;
    handleSubmitError?: (errors: any) => void;
    handlePrivacyButtonClicked?: (errors: any) => void;
    handleCancel?: () => void;
    loading?: boolean;
    newVisitor?: boolean;
    isModal?: boolean,
    isModalOpen?: boolean,
    submitButtonDisabled?: boolean,
    values?: IVisitorInFormProps;
}

const ChangePasswordForm = ({
    errors,
    handleSubmit,
    handleSubmitError,
    handleCancel,
    loading = false,
    isModal = false,
    isModalOpen = false,
    submitButtonDisabled = false,
    values,
}: VisitorInFormProps) => {
    const [form] = Form.useForm();

    const emailCol = 24
    const buttonCol = 24

    const dispatch = useDispatch<AppDispatch>();

    const fetchProfileProjects = () => {
        dispatch(getProfileProjects(getProfileId))
    }

    useEffect(() => {
        fetchProfileProjects()
    }, [])

    useEffect(() => {
        if(!isModalOpen) {
            form.setFieldsValue({})
        }
    }, [isModalOpen])

    useEffect(() => {
        if (values && form) {
            const projectsId =  values.projects?.map((item: { id: any; }) => item.id)
            console.log("values", values)
            form.setFieldsValue({
                ...values,
                projects_id: projectsId
            });
        }
    }, [values]);

    useEffect(() => {
        if (errors && form) {
            const formattedErrors: any = [];

            Object.keys(errors).forEach((key: string) => {
                formattedErrors.push({
                    name: key,
                    errors: [errors[key]]
                });
            });

            form.setFields(formattedErrors);
        }
    }, [errors]);

    const handleCancelClicked = () =>{
        handleCancel ? handleCancel() : null
    }

    const formSubmit = () => {
        form.submit()
    }

    const formFields = (
            <div className="form-fields-container">
            {
                (
                    <Row gutter={16}>
                        <Col span={emailCol}>
                            <Item
                            label="Current Password"
                            name="old_password"
                            rules={[{ required: true, message: 'Please input your current password!' }]}
                            >
                                <Input.Password />
                            </Item>
                        </Col>
                        <Col span={emailCol}>
                        <Item
                            label="New Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your new password!' }]}
                            >
                                <Input.Password />
                            </Item>
                        </Col>
                        <Col span={emailCol}>
                        <Item
                            label="Retype New Password"
                            name="password_confirmation"
                            rules={[{ required: true, message: 'Please re-type your new password!' }]}
                            >
                                <Input.Password />
                            </Item>
                        </Col>
                    </Row>
                )
            }
        </div>
    )

    const formElement = (
        <Form 
            form={form} 
            onFinish={handleSubmit} 
            onFinishFailed={handleSubmitError}
        >
            {formFields}
            {
                isModal ? null : (
                    <Row gutter={16}>
                        <Col span={buttonCol} className="form__button">
                            <SaveButton
                                loading={loading}
                                disabled={submitButtonDisabled}
                            />
                        </Col>
                    </Row>
                )
            }
        </Form>
    )

    return isModal ? (
        <Modal 
            title="Handle your password" 
            open={isModalOpen} 
            onOk={formSubmit} 
            onCancel={handleCancelClicked}
            okButtonProps={{ disabled: submitButtonDisabled }}
        >
            {formElement}
        </Modal>
    ) : (
        <div className="">
            {formElement}
        </div>
    );
};

export default ChangePasswordForm;
