import { useEffect } from "react";

import Col from "../../ui/Col/Col";
import Row from "../../ui/Row/Row";
import Form from "../Form";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { searcOpenVisitorLogs, selectOpenVisitorLogs } from "../../store/slices/app";
import SaveButton from "../../ui/SaveButton/SaveButton";

const { Item } = Form;

export interface IVisitorOutFormProps {
    visitor_log_id?: string;
}

export interface VisitorOutFormProps {
    errors?: any;
    handleSubmit?: (values: any) => void;
    handleSubmitError?: (errors: any) => void;
    loading?: boolean;
    values?: IVisitorOutFormProps;
}

let previusQuery = ""

const VisitorOutForm = ({
    errors,
    handleSubmit,
    handleSubmitError,
    loading = false,
    values,
}: VisitorOutFormProps) => {
    const [form] = Form.useForm();

    const emailCol = 24
    const buttonCol = 24

    const dispatch = useDispatch<AppDispatch>();
    const openVisitorLogs = useSelector(selectOpenVisitorLogs);

    useEffect(() => {
        if (values && form) {
            form.setFieldsValue(values);
        }
    }, [values]);

    useEffect(() => {
        if (errors && form) {
            const formattedErrors: any = [];

            Object.keys(errors).forEach((key: string) => {
                formattedErrors.push({
                    name: key,
                    errors: [errors[key]]
                });
            });

            form.setFields(formattedErrors);
        }
    }, [errors]);

    const fetchOpenVisitorLogs = (value: string) => {
        const valueLength = value?.length
        if (valueLength >= 3 || (valueLength == 2 && previusQuery?.length > valueLength)) {
            dispatch(searcOpenVisitorLogs({ search: value }))
        }
        previusQuery = value
    }

    const getOpenVisitorOptions = () => openVisitorLogs?.map(item => {
        return {
            value: item.id,
            label: `${item.visitor.firstname} ${item.visitor.lastname}${item.visitor.company ? ` (${item.visitor.company})` : ""}`,
        }
    })

    return (
        <div className="visitor-out-form">
            <Form form={form} onFinish={handleSubmit} onFinishFailed={handleSubmitError}>
                <Row gutter={16}>
                    <Col span={emailCol}>
                        <Item
                            name="visitor_log_id"
                            rules={[{ required: true, message: "Please select a valid Referrer!" }]}
                        >
                            <Select
                                placeholder="Start typing your name"
                                data-testid="registration-form-item"
                                optionFilterProp="children"
                                filterOption={(input, option: any) => {
                                    const label = "" + option?.label;
                                    return label.toLowerCase().includes(input.toLowerCase())
                                }}
                                onSearch={fetchOpenVisitorLogs}
                                showSearch
                                options={getOpenVisitorOptions()}
                            />

                        </Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={buttonCol} className="form__button">
                        <SaveButton
                            loading={loading}
                        />
                    </Col>

                </Row>
            </Form>
        </div>
    );
};

export default VisitorOutForm;
