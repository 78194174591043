export const getToken = (): string | undefined => {
  const token = localStorage.getItem("token");

  return token || undefined;
};

export const setToken = (token: string): void => {
  localStorage.setItem("token", token);
};

export const clearToken = (): void => {
  localStorage.removeItem("token");
};
