import { SpinProps as AntSpinProps } from "antd/lib/spin";
import { Spin as AntSpin } from "antd";

export interface SpinProps extends AntSpinProps {
  children: any;
  fullPage?: boolean;
}

const Spin = (props: SpinProps) => {
  return <AntSpin />;
};
export default Spin;
