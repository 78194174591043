import React from "react";
import AntForm, {
    FormItemProps as AntFormItemProps,
    FormProps as AntFormProps
} from "antd/lib/form";

export interface FormProps extends AntFormProps {
    ref?: any;
}
export interface FormItemProps<Values = any> extends AntFormItemProps<Values> {}

const Form: React.ForwardRefExoticComponent<FormProps> & {
    Item: <Values = any>(
        props: FormItemProps<Values>
    ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    useForm: typeof AntForm.useForm;
    useWatch: typeof AntForm.useWatch;
} = React.forwardRef(({ children, ...props }: any, ref: any) => (
    <AntForm {...props} ref={ref}>
        {children}
    </AntForm>
)) as any;

Form.Item = AntForm.Item;
Form.useForm = AntForm.useForm;
Form.useWatch = AntForm.useWatch;

export default Form;
