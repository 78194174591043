import {
    createAsyncThunk,
    createDraftSafeSelector,
    createSlice,
} from "@reduxjs/toolkit";
// import { INode } from "../../components/layout/Layout";
import { RootState } from "../index";

import axios from "../../utils/client";
import { buildQuery } from "../../utils/buildQuery";
import { IVisitorLog } from "./app";
import { saveAs } from "file-saver";
import { DEFAULT_EXPORT_FILENAME } from "../../utils/config";

export type IAdminReducer = {
    loading: boolean;
    downloading: boolean;
    error?: any;
    visitorLogs?: IVisitorLog[] | undefined;
    links?: any;
    meta?: any;
    // TODO: Remove when is ready get events api request
};
export type IVisitorLogPaginatedResponse = {
    data: IVisitorLog[];
    links?: any;
    meta?: any;
    // TODO: Remove when is ready get events api request
};

const initialState: IAdminReducer = {
    loading: false,
    downloading: false,
    visitorLogs: [],
    error: undefined,
    meta: undefined,
    links: undefined
};

export const searcOpenVisitorLogs = createAsyncThunk<IVisitorLogPaginatedResponse, any | undefined>(
    "admin/searcOpenVisitorLogs",
    async (props: any, thunkApi) => {
        const {
            user: { token, profileId }
        } = thunkApi.getState() as RootState;
        try {
            const headers = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            let query = ""
            if (props) {
                query = buildQuery(props)
            }
            const res = await axios.get(
                `/v1/${profileId}/visitor-logs?${query}`,
                headers
            );
            return res.data;
        } catch (error) {
            return thunkApi.rejectWithValue({
                error,
            });
        }
    }
);

export const downloadVisitorsLog = createAsyncThunk<any, any | undefined>(
    "admin/downloadVisitorsLog",
    async (props: any, thunkApi) => {
        const {
            user: { token, profileId }
        } = thunkApi.getState() as RootState;
        try {
            const res = await axios(
                {
                    url: `/v1/${profileId}/visitor-logs/export`,
                    method: "get",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: props,
                    responseType: "blob",
                }
            );
            const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            return saveAs(blob, DEFAULT_EXPORT_FILENAME);
        } catch (error: any) {
            return thunkApi.rejectWithValue(
                error.response
                    ? {
                        code: error.response.status,
                        message: error.response.data.message,
                    }
                    : error.toString()
            );
        }
    }
);

export const adminSlice = createSlice({
    name: "adminPage",
    initialState,
    reducers: {
        resetAdminVisitorLogs: (state) => {
            state.visitorLogs = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(searcOpenVisitorLogs.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(searcOpenVisitorLogs.fulfilled, (state, { payload }) => {
            state.visitorLogs = payload.data;
            state.meta = payload.meta;
            state.links = payload.links;
            state.loading = false;
        })

        builder.addCase(searcOpenVisitorLogs.rejected, (state, { error }) => {
            state.loading = false;
            state.error = error;
        });
        builder.addCase(downloadVisitorsLog.pending, (state) => {
            state.downloading = true;
        });

        builder.addCase(downloadVisitorsLog.fulfilled, (state, { payload }) => {
            state.downloading = false;
        })

        builder.addCase(downloadVisitorsLog.rejected, (state, { error }) => {
            state.downloading = false;
        });
    },
});

const selectState = (state: RootState) => state;
const selectSelf = createDraftSafeSelector(selectState, ({ adminPage }) => adminPage);
export const selectAdminLoading = createDraftSafeSelector(
    selectSelf,
    ({ loading }) => loading
);
export const selectAdminDownloading = createDraftSafeSelector(
    selectSelf,
    ({ downloading }) => downloading
);

export const selectAdminVisitorLogs = createDraftSafeSelector(
    selectSelf,
    ({ visitorLogs }) => visitorLogs
);

export const selectAdminVisitorLogsMeta = createDraftSafeSelector(
    selectSelf,
    ({ meta }) => meta
);

export const selectAdminVisitorLogsLinks = createDraftSafeSelector(
    selectSelf,
    ({ links }) => links
);

export const {
    resetAdminVisitorLogs,
} = adminSlice.actions;

export default adminSlice.reducer;
