import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../index";
import axios from "../../utils/client";
import { clearToken, setToken } from "../../utils/auth";
import { PROFILE_GUEST } from "../../utils/roles";

interface RequestAction {
  loading: boolean;
  success?: boolean;
  sent?: boolean;
  error: any;
}

export type IProject = {
  id: string; 
  title: string;
};

export type IUserReducer = {
  identity?: any;
  token?: string;
  isInternal: boolean;
  selectedEpisode: number | undefined;
  loading: boolean;
  changingPassword: boolean;
  error?: any;
  login: RequestAction;
  register: RequestAction;
  answerEvaluationForm: RequestAction;
  changePassword: RequestAction;
  profileId?: string | undefined;
  projectId?: string | undefined;
  projects: IProject[];
  referrerId?: any;
  qrCodeSrc?: string;
  requireOTP?: boolean;
};

const initialState: IUserReducer = {
  identity: undefined,
  isInternal: false,
  selectedEpisode: undefined,
  token: undefined,
  loading: false,
  changingPassword: false,
  error: undefined,
  profileId: undefined,
  projectId: undefined,
  projects: [],
  qrCodeSrc: undefined,
  requireOTP: false,
  login: {
    loading: false,
    error: undefined,
  },
  register: {
    loading: false,
    error: undefined,
  },
  answerEvaluationForm: {
    loading: false,
    success: false,
    sent: false,
    error: undefined,
  },
  changePassword: {
    loading: false,
    success: false,
    sent: false,
    error: undefined,
  },
};

type AuthType = "login" | "register" | "logout" | "complete-login";

const authThunk = (type: AuthType) =>
  createAsyncThunk<any, any | undefined>(
    `app/${type}`,
    async (values, thunkApi) => {
      try {
        const {
          app: { id },
          user: { token },
        } = thunkApi.getState() as RootState;
        const headers =
        ["complete-login", "logout"].includes(type)
            ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
            : undefined;
        const res = await axios.post(`/v1/${type}`, values, headers);
        return res.data;
      } catch (error: any) {
        return thunkApi.rejectWithValue(
          error.response
            ? {
              code: error.response.status,
              body: error.response.data,
            }
            : error.toString()
        );
      }
    }
  );

export const register = authThunk("register");
export const login = authThunk("login");
export const completeLogin = authThunk("complete-login");
export const logout = authThunk("logout");

export const getMe = createAsyncThunk<any>(
  `user/getMe`,
  async (values, thunkApi) => {
    try {
      const {
        user: { token },
      } = thunkApi.getState() as RootState;
      if (token) {
        const res = await axios.get(`/v1/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return res.data;
      }

      // return thunkApi.rejectWithValue({
      //   code: 401,
      // });
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response
          ? {
            code: error.response.status,
            message: error.response.data.message,
          }
          : error.toString()
      );
    }
  }
);

export const verifyPassword = createAsyncThunk<any, any>(
  `user/verifyPassword`,
  async (values, thunkApi) => {
    try {
      const {
        user: { token },
      } = thunkApi.getState() as RootState;
      if (token) {
        const res = await axios.post(`/v1/verify-password`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return res.data.data;
      }

      // return thunkApi.rejectWithValue({
      //   code: 401,
      // });
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response
          ? {
            code: error.response.status,
            message: error.response.data.message,
          }
          : error.toString()
      );
    }
  }
);

export const changePassword = createAsyncThunk<any, any>(
  `user/changePassword`,
  async (values, thunkApi) => {
    try {
      const {
        user: { token },
      } = thunkApi.getState() as RootState;
      if (token) {
        const res = await axios.post(`/v1/change-password`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return res.data.data;
      }
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response
          ? error.response.data?.errors
          : error.toString()
      );
    }
  }
);

export const getProfileProjects = createAsyncThunk<any, any | undefined>(
  "user/getProfileProjects",
  async (props: any, thunkApi) => {
      const {
          user: { token, profileId }
      } = thunkApi.getState() as RootState;
      try {
          const res = await axios(
              {
                  url: `/v1/${profileId}/projects`,
                  method: "get",
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
                  params: props,
              }
          );
          return res.data;
      } catch (error: any) {
          return thunkApi.rejectWithValue(
              error.response
                  ? {
                      code: error.response.status,
                      message: error.response.data.message,
                  }
                  : error.toString()
          );
      }
  }
);

const setProjectData = (payload: any, state: IUserReducer) => {
  if (payload.user.roles[0]) {
    switch (payload.user.roles[0].name) {
      case PROFILE_GUEST:
        if (payload.user.profile.projects?.length === 1) {
          state.projectId = payload.user.profile.projects[0].id;
        }
        break
    }
    state.projects = payload.user.profile.projects;
  }
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProfileId: (state: IUserReducer, { payload }) => {
      state.profileId = payload;
    },
    setProjectId: (state: IUserReducer, { payload }) => {
      state.projectId = payload;
    },
    changeIsInternalUser: (state: IUserReducer, { payload }) => {
      state.isInternal = payload;
    },
    stickToken: (state, { payload }) => {
      state.token = payload;
    },
    // logout: (state) => {
    //     state.token = undefined;
    //     state.identity = undefined;

    //     clearToken();
    // },
    hasSelectEpisode: (state, { payload }) => {
      state.selectedEpisode = payload;
    },
    resetSelectedEpisode: (state) => {
      state.selectedEpisode = undefined;
    },
    clearErrorAnswerEvaluation: (state) => {
      state.answerEvaluationForm.error = undefined;
    },
    setAnswerEvaluationSent: (state) => {
      state.answerEvaluationForm.sent = true;
    },
    resetAnswerEvaluationSent: (state) => {
      state.answerEvaluationForm.sent = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.register.loading = true;
    });

    builder.addCase(register.fulfilled, (state) => {
      state.register.loading = false;
    });

    builder.addCase(register.rejected, (state, { error }) => {
      state.register.loading = false;
      state.register.error = error;
    });

    builder.addCase(login.pending, (state) => {
      state.login.loading = true;
    });

    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.login.loading = false;
      if(payload.user) {
        state.identity = payload.user;
        setProjectData(payload, state)
        state.profileId = payload.user.profile.id;
      }

      state.qrCodeSrc = payload.qr_code_src;
      state.requireOTP = payload.require_otp;
      state.token = payload.token;
      setToken(payload.token);
    });

    builder.addCase(login.rejected, (state, { error }) => {
      state.login.loading = false;
      state.login.error = error;
      state.identity = undefined;
    });

    builder.addCase(completeLogin.pending, (state) => {
      state.login.loading = true;
    });

    builder.addCase(completeLogin.fulfilled, (state, { payload }) => {
      state.login.loading = false;
      state.identity = payload.user;
      state.profileId = payload.user.profile.id;
      setProjectData(payload, state)
    });

    builder.addCase(completeLogin.rejected, (state, { error }) => {
      state.login.loading = false;
      state.login.error = error;
    });

    builder.addCase(getMe.pending, (state) => {
      // state.login.loading = true;
    });

    builder.addCase(getMe.fulfilled, (state, { payload }) => {
      state.login.loading = false;
      state.identity = payload.user;
      state.profileId = payload.user.profile.id;
      setProjectData(payload, state)
    });

    builder.addCase(getMe.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
      state.token = undefined;
      clearToken()
    });

    builder.addCase(logout.pending, (state) => {
      // state.login.loading = true;
    });

    builder.addCase(logout.fulfilled, (state, { payload }) => {
      state.token = undefined;
      state.identity = undefined;
      state.qrCodeSrc = undefined;
      state.requireOTP =false;      
      clearToken();
    });

    builder.addCase(logout.rejected, (state, { error }) => {
      // state.login.loading = false;
      // state.login.error = error;
    });

    builder.addCase(changePassword.pending, (state) => {
      state.changePassword.error = undefined
      state.changePassword.loading = true
      state.changePassword.sent = false
    });

    builder.addCase(changePassword.fulfilled, (state) => {
      state.changePassword.loading = false
      state.changePassword.sent = true
    });

    builder.addCase(changePassword.rejected, (state, { payload }) => {
      state.changePassword.loading = false
      state.changePassword.error = payload
      state.changePassword.sent = true
    });
  },
});

export const {
  setProfileId,
  setProjectId,
  changeIsInternalUser,
  stickToken,
  clearErrorAnswerEvaluation,
  hasSelectEpisode,
  resetSelectedEpisode,
  setAnswerEvaluationSent,
  resetAnswerEvaluationSent,
} = userSlice.actions;

const selectState = (state: RootState) => state;
const selectSelf = createDraftSafeSelector(selectState, (state) => state.user);
export const selectUser = createDraftSafeSelector(
  selectSelf,
  ({ identity, token, loading, error, selectedEpisode, qrCodeSrc, requireOTP }) => ({
    identity,
    token,
    loading,
    error,
    isModerator: identity?.is_moderator,
    isChairman: identity?.is_chairman,
    selectedEpisode,
    qrCodeSrc,
    requireOTP
  })
);

export const getProfileId = createDraftSafeSelector(
  selectSelf,
  ({ profileId }) => profileId
);
export const getProjectId = createDraftSafeSelector(
  selectSelf,
  ({ projectId }) => projectId
);
export const getProjects = createDraftSafeSelector(
  selectSelf,
  ({ projects }) => projects
);
export const selectIsInternalUser = createDraftSafeSelector(
  selectSelf,
  ({ isInternal }) => isInternal
);

export const selectUserAnswers = createDraftSafeSelector(
  selectSelf,
  ({ identity }) => identity.answers
);

export const selectUserEpisodes = createDraftSafeSelector(
  selectSelf,
  ({ identity }) => identity?.episodes || []
);

export const selectAvailableEvaluations = createDraftSafeSelector(
  selectSelf,
  ({ identity }) =>
    identity?.questions.filter((item: any) => item.is_evaluation_open)
);

export const selectLogin = createDraftSafeSelector(
  selectSelf,
  ({ login }) => login
);
export const selectIsLogging = createDraftSafeSelector(
  selectLogin,
  ({ loading }) => loading
);
export const selectRegister = createDraftSafeSelector(
  selectSelf,
  ({ register }) => register
);
export const selectIsRegistering = createDraftSafeSelector(
  selectRegister,
  ({ loading }) => loading
);
export const selectAnswerEvaluation = createDraftSafeSelector(
  selectSelf,
  ({ answerEvaluationForm }) => answerEvaluationForm
);
export const selectIsAnsweringEvaluation = createDraftSafeSelector(
  selectAnswerEvaluation,
  ({ loading }) => loading
);
export const selectIsAnsweredEvaluation = createDraftSafeSelector(
  selectAnswerEvaluation,
  ({ success }) => success
);
export const selectHaveSentAnsweredEvaluation = createDraftSafeSelector(
  selectAnswerEvaluation,
  ({ sent }) => sent
);

export const selectChangePassword = createDraftSafeSelector(
  selectSelf,
  ({ changePassword }) => changePassword
);

export const selectUserErrors = createDraftSafeSelector(
  selectChangePassword,
  ({ error }) => error
);
export default userSlice.reducer;
