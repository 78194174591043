import { createAsyncThunk, createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/client";
import { RootState } from "../index";

export const MODE_IN = "in";
export const MODE_OUT = "out";

export type IModeType = typeof MODE_IN | typeof MODE_OUT | undefined;
export type INewVisitorType = boolean | undefined;

export type IPublicReducer = {
    loading: boolean;
    newVisitor?: INewVisitorType;
    mode: IModeType;
    completed: boolean;
    error?: object;
};

const initialState: IPublicReducer = {
    loading: false,
    newVisitor: undefined,
    mode: undefined,
    completed: false,
    error: undefined,
};

export const setIn = createAsyncThunk<any, any>(
    `user/setIn`,
    async (values, thunkApi) => {
        try {
            const {
                user: { token, profileId, projectId }
            } = thunkApi.getState() as RootState;
            const res = await axios.post(
                `/v1/${profileId}/${projectId}/in`,
                values,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return res.data.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue(
                error.response
                    ? {
                        code: error.response.status,
                        body: error.response.data,
                    }
                    : error.toString()
            );
        }
    }
);
export const setOut = createAsyncThunk<any, any>(
    `user/setOut`,
    async (values, thunkApi) => {
        try {
            const {
                user: { token, profileId, projectId }
            } = thunkApi.getState() as RootState;
            const res = await axios.post(
                `/v1/${profileId}/${projectId}/out`,
                values,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return res.data.data;
        } catch (error: any) {
            return thunkApi.rejectWithValue(
                error.response
                    ? {
                        code: error.response.status,
                        body: error.response.data,
                    }
                    : error.toString()
            );
        }
    }
);

export const publicSlice = createSlice({
    name: "publicPage",
    initialState,
    reducers: {
        setMode: (state, { payload }) => {
            state.mode = payload;
        },
        setNewVisitor: (state, { payload }) => {
            state.newVisitor = payload;
        },
        setCompleted: (state, { payload }) => {
            state.completed = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setIn.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(setIn.fulfilled, (state) => {
            state.loading = false;
            state.completed = true;
        });
        builder.addCase(setIn.rejected, (state, { error }) => {
            state.loading = false;
            state.error = error;
        });
        builder.addCase(setOut.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(setOut.fulfilled, (state) => {
            state.loading = false;
            state.completed = true;
        });
        builder.addCase(setOut.rejected, (state, { error }) => {
            state.loading = false;
            state.error = error;
        });
    }
});

export const { setMode, setNewVisitor, setCompleted } = publicSlice.actions;

const selectState = (state: RootState) => state;
const selectSelf = createDraftSafeSelector(selectState, ({ publicPage }: any) => publicPage);
export const selectPublic = createDraftSafeSelector(selectSelf, ({ loading, mode, newVisitor, completed, error }) => ({ loading, mode, newVisitor, completed, error }));

export default publicSlice.reducer;
