import { createDraftSafeSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";

export type IModalsReducer = {
    modal: string | null;
    props?: any;
};

const initialState: IModalsReducer = {
    modal: null,
    props: {}
};

export const modalsSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        showModal: (state, { payload }) => {
            state.modal = payload.modal;
            state.props = payload.props;
        },
        closeModal: (state) => {
            state.modal = null;
            state.props = {};
        }
    }
});

export const { showModal, closeModal } = modalsSlice.actions;

const selectState = (state: RootState) => state;
const selectSelf = createDraftSafeSelector(selectState, ({ modals }: any) => modals);
export const selectCurrentModal = createDraftSafeSelector(selectSelf, ({ modal, props }) => ({
    modal,
    props
}));

export default modalsSlice.reducer;
