import { useEffect } from "react";

import Input from "../../ui/Input/Input";
import Col from "../../ui/Col/Col";
import Row from "../../ui/Row/Row";
import Form from "../Form";
import {  Modal, Select, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import SaveButton from "../../ui/SaveButton/SaveButton";
import { IProject, getProfileId, getProfileProjects, getProjects } from "../../store/slices/user";

const { Item } = Form;

export interface IVisitorInFormProps {
    id?: number;
    email?: string;
    firstname?: string;
    lastname?: string;
    company?: string;
    referrer_id?: string;
    privacy_check?: string;
    visitor_id?: string;
    profile?: any;
    projects?: any;
}

export interface VisitorInFormProps {
    errors?: any;
    handleSubmit?: (values: any) => void;
    handleSubmitError?: (errors: any) => void;
    handlePrivacyButtonClicked?: (errors: any) => void;
    handleCancel?: () => void;
    loading?: boolean;
    newVisitor?: boolean;
    isModal?: boolean,
    isModalOpen?: boolean,
    submitButtonDisabled?: boolean,
    values?: IVisitorInFormProps;
}

const ReferrerForm = ({
    errors,
    handleSubmit,
    handleSubmitError,
    handleCancel,
    loading = false,
    isModal = false,
    isModalOpen = false,
    submitButtonDisabled = false,
    values,
}: VisitorInFormProps) => {
    const [form] = Form.useForm();

    const emailCol = 24
    const buttonCol = 24

    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector(getProjects);

    const fetchProfileProjects = () => {
        dispatch(getProfileProjects(getProfileId))
    }

    useEffect(() => {
        fetchProfileProjects()
    }, [])

    useEffect(() => {
        if(!isModalOpen) {
            form.setFieldsValue({})
        }
    }, [isModalOpen])

    useEffect(() => {
        if (values && form) {
            const projectsId =  values.projects?.map((item: { id: any; }) => item.id)
            console.log("values", values)
            form.setFieldsValue({
                ...values,
                projects_id: projectsId
            });
        }
    }, [values]);

    useEffect(() => {
        if (errors && form) {
            const formattedErrors: any = [];

            Object.keys(errors).forEach((key: string) => {
                formattedErrors.push({
                    name: key,
                    errors: [errors[key]]
                });
            });

            form.setFields(formattedErrors);
        }
    }, [errors]);

    const getReferrerOptions = () => projects?.map((project: IProject) => {
        return {
            value: project.id,
            label: `${project.title}`,
        }
    })

    const handleCancelClicked = () =>{
        handleCancel ? handleCancel() : null
    }

    const formSubmit = () => {
        form.submit()
    }

    const formFields = (
            <div className="form-fields-container">
            {
                (
                    <Row gutter={16}>
                        <Col span={emailCol}>
                            <Item
                                label="Search for location"
                                name="projects_id"
                                rules={[{ required: true, message: "Please select a valid Visitor!" }]}
                            >
                                <Select
                                    placeholder="Start typing"
                                    data-testid="registration-form-item"
                                    optionFilterProp="children"
                                    mode="multiple"
                                    filterOption={(input, option: any) => {
                                        const label = "" + option?.label;
                                        return label.toLowerCase().includes(input.toLowerCase())
                                    }}
                                    // onSearch={fetchVisitors}
                                    showSearch
                                    options={getReferrerOptions()}
                                />

                            </Item>
                        </Col>
                        <Col span={emailCol}>
                            <Item
                                label="Firstname"
                                data-testid="form-firstname"
                                name="firstname"
                                rules={[{ required: true, message: "Please insert your Firstname!" }]}
                            >
                                <Input placeholder="Firstname" />
                            </Item>
                        </Col>
                        <Col span={emailCol}>
                            <Item
                                label="Lastname"
                                data-testid="form-lastname"
                                name="lastname"
                                rules={[{ required: true, message: "Please insert your Lastname!" }]}
                            >
                                <Input placeholder="Lastname" />
                            </Item>
                        </Col>
                        <Col span={emailCol}>
                            <Item label="Active" valuePropName="checked" name="is_public">
                                <Switch />
                            </Item>
                        </Col>
                    </Row>
                )
            }
        </div>
    )

    const formElement = (
        <Form 
        key={`referrer-form-${values?.id || "new"}`}
            form={form} 
            onFinish={handleSubmit} 
            onFinishFailed={handleSubmitError}
        >
            {formFields}
            {
                isModal ? null : (
                    <Row gutter={16}>
                        <Col span={buttonCol} className="form__button">
                            <SaveButton
                                loading={loading}
                                disabled={submitButtonDisabled}
                            />
                        </Col>
                    </Row>
                )
            }
        </Form>
    )

    return isModal ? (
        <Modal 
            title="Referrer" 
            open={isModalOpen} 
            onOk={formSubmit} 
            onCancel={handleCancelClicked}
            okButtonProps={{ disabled: submitButtonDisabled }}
        >
            {formElement}
        </Modal>
    ) : (
        <div className="">
            {formElement}
        </div>
    );
};

export default ReferrerForm;
