import { selectUser } from "../store/slices/user";
import { useAppSelector } from "../store/hooks";
import PublicPage from "../components/PublicPage";
import PrivatePage from "../components/PrivatePage";

const WithRole = (Component: any) => (props: any) => {
    const { identity } = useAppSelector(selectUser);

    const hasRole = (roleName: string): boolean => {
        return identity?.roles?.find((role: any) => role.name === roleName)
    }

    const getPage = () => {
        if (hasRole("profile_guest")) {
            return <PublicPage />
        }
        else if (hasRole("profile_admin")) {
            return <PrivatePage />
        }
        return null
    }

    return identity ? getPage() : <Component {...props} />
};

export default WithRole;
