import { useEffect, useState } from "react";

import Input from "../../ui/Input/Input";
import Col from "../../ui/Col/Col";
import Row from "../../ui/Row/Row";
import Form from "../Form";
import { Checkbox, Select } from "antd";
import { searchReferrers, searcVisitors, selectReferrers, selectVisitors } from "../../store/slices/app";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import SaveButton from "../../ui/SaveButton/SaveButton";

const { Item } = Form;

export interface IVisitorInFormProps {
    email?: string;
    firstname?: string;
    lastname?: string;
    company?: string;
    referrer_id?: string;
    privacy_check?: string;
    visitor_id?: string;
}

export interface VisitorInFormProps {
    errors?: any;
    handleSubmit?: (values: any) => void;
    handleSubmitError?: (errors: any) => void;
    handlePrivacyButtonClicked?: (errors: any) => void;
    loading?: boolean;
    newVisitor?: boolean;
    values?: IVisitorInFormProps;
}

let previusQuery = ""

const VisitorInForm = ({
    errors,
    handleSubmit,
    handleSubmitError,
    handlePrivacyButtonClicked,
    loading = false,
    values,
    newVisitor
}: VisitorInFormProps) => {
    const [form] = Form.useForm();

    const emailCol = 24
    const buttonCol = 24

    const dispatch = useDispatch<AppDispatch>();
    const referrers = useSelector(selectReferrers);
    const visitors = useSelector(selectVisitors);
    const [submittable, setSubmittable] = useState(false);

    const watch = Form.useWatch([], form);

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
          () => {
            setSubmittable(true);
          },
          () => {
            setSubmittable(false);
          },
        );
      }, [watch]);

    useEffect(() => {
        if (values && form) {
            form.setFieldsValue(values);

        }
    }, [values]);

    useEffect(() => {
        if (errors && form) {
            const formattedErrors: any = [];

            Object.keys(errors).forEach((key: string) => {
                formattedErrors.push({
                    name: key,
                    errors: [errors[key]]
                });
            });

            form.setFields(formattedErrors);
        }
    }, [errors]);

    // const debounceFetcher = useMemo(() => {
    // const loadOptions = (value: string) => {
    //   fetchRef.current += 1;
    //   const fetchId = fetchRef.current;
    //   setOptions([]);
    //   setFetching(true);

    //   fetchOptions(value).then((newOptions) => {
    //     if (fetchId !== fetchRef.current) {
    //       // for fetch callback order
    //       return;
    //     }

    //     setOptions(newOptions);
    //     setFetching(false);
    //   });
    // };

    // return debounce(loadOptions, debounceTimeout);
    //   }, [fetchOptions, debounceTimeout]);

    const fetchReferrers = (value: string) => {
        const valueLength = value?.length
        if (valueLength >= 3 || (valueLength == 2 && previusQuery?.length > valueLength)) {
            dispatch(searchReferrers({ search: value }))
        }
        previusQuery = value
    }

    const getReferrerOptions = () => referrers?.map(referrer => {
        return {
            value: referrer.id,
            label: `${referrer.firstname} ${referrer.lastname}`,
        }
    })
    const fetchVisitors = (value: string) => {
        const valueLength = value?.length
        if (valueLength >= 3 || (valueLength == 2 && previusQuery?.length > valueLength)) {
            dispatch(searcVisitors({ search: value }))
        }
        previusQuery = value
    }

    const getVisitorOptions = () => visitors?.map(visitor => {
        return {
            value: visitor.id,
            label: `${visitor.firstname} ${visitor.lastname}${visitor.company ? ` (${visitor.company})` : ""}`,
        }
    })

    // const visitorOptions = [
    //     {
    //         value: 5,
    //         label: "Roberto Belotti",
    //     },
    //     {
    //         value: 6,
    //         label: "Simone",
    //     },
    //     {
    //         value: 7,
    //         label: "Marco",
    //     },
    // ]

    return (
        <div className="visitor-in-form">
            <Form form={form} onFinish={handleSubmit} onFinishFailed={handleSubmitError}>
                <div className="form-fields-container">
                    {
                        newVisitor ? (
                            <Row gutter={16}>
                                <Col span={emailCol}>
                                    {/* <label>Firstname</label> */}
                                    <Item
                                        data-testid="form-firstname"
                                        name="firstname"
                                        rules={[{ required: true, message: "Please insert your Firstname!" }]}
                                    >
                                        <Input placeholder="Firstname *" />
                                    </Item>
                                </Col>
                                <Col span={emailCol}>
                                    {/* <label>Lastname</label> */}
                                    <Item
                                        data-testid="form-lastname"
                                        name="lastname"
                                        rules={[{ required: true, message: "Please insert your Lastname!" }]}
                                    >
                                        <Input placeholder="Lastname *" />
                                    </Item>
                                </Col>
                                <Col span={emailCol}>
                                    {/* <label>Company</label> */}
                                    <Item
                                        data-testid="form-company"
                                        name="company"
                                        rules={[{ required: true, message: "Please insert your company name!" }]}
                                    >
                                        <Input placeholder="Company *" />
                                    </Item>
                                </Col>
                                <Col span={emailCol}>
                                    {/* <label>Role</label> */}
                                    <Item
                                        data-testid="form-role"
                                        name="role"
                                    >
                                        <Input placeholder="Role" />
                                    </Item>
                                </Col>
                                <Col span={emailCol}>
                                    {/* <label>Email</label> */}
                                    <Item
                                        data-testid="form-email"
                                        name="email"
                                        rules={[{ type: "email" }]}
                                    >
                                        <Input placeholder="Email" />
                                    </Item>
                                </Col>
                            </Row>
                        ) : (
                            <Row gutter={16}>
                                <Col span={emailCol}>
                                    {/* <label>Search for your name</label> */}
                                    <Item
                                        name="visitor_id"
                                        rules={[{ required: false, message: "Please select a valid Visitor!" }]}
                                    >
                                        <Select
                                            placeholder="Start typing your name *"
                                            data-testid="registration-form-item"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) => {
                                                const label = "" + option?.label;
                                                return label.toLowerCase().includes(input.toLowerCase())
                                            }}
                                            onSearch={fetchVisitors}
                                            showSearch
                                            options={getVisitorOptions()}
                                        />

                                    </Item>
                                </Col>
                            </Row>
                        )
                    }

                    <Row gutter={16}>
                        <Col span={emailCol}>
                            {/* <label>Referrer</label> */}
                            <Item
                                name="referrer_id"
                                rules={[{ required: true, message: "Please select a valid Referrer!" }]}
                            >
                                <Select
                                    placeholder="Referrer *"
                                    data-testid="registration-form-item"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        const label = "" + option?.label;
                                        return label.toLowerCase().includes(input.toLowerCase())
                                    }}
                                    onSearch={fetchReferrers}
                                    showSearch
                                    options={getReferrerOptions()}
                                />

                            </Item>
                        </Col>

                        <Col span={emailCol}>
                            <Item
                                name="privacy_check"
                                valuePropName="checked"
                                rules={[() => ({
                                    validator(_, value) {
                                        if (value === true) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error("Privacy policy is mandatory"));
                                    },
                                }),]}
                            >
                                <Checkbox>
                                    Privacy *
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={emailCol}>
                            <div style={{marginBottom: "1rem"}}>
                                <a onClick={handlePrivacyButtonClicked}>ADM Media Consulting SA Privacy Policy</a>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row gutter={16}>
                    <Col span={buttonCol} className="form__button">
                        <SaveButton
                            disabled={!submittable}
                            loading={loading}
                        />
                    </Col>

                </Row>
            </Form>
        </div>
    );
};

export default VisitorInForm;
