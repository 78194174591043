import AllHocs from '../hocs/AllHocs';
import logo from '../logo.svg';

function Homepage() {
    return (
        <AllHocs>

            <img src={logo} className="App-logo" alt="logo" />
            <p>
                Edit <code>src/App.tsx</code> and save to reload.
            </p>
            <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
            >
                Learn React
            </a>

        </AllHocs>
    )
}


export default Homepage