import { pipe } from "ramda";

import WithAuth from "./WithAuth";
import WithRole from "./WithRole";

const withAll = pipe(
  WithAuth,
  WithRole,
);

const AllHocs = ({ children }: any) => {
  return children;
};

export default withAll(AllHocs);
