import React from "react";
import { Input as AntInput, InputProps as AntInputProps } from "antd";

import "./Input.scss";

export interface InputProps extends AntInputProps {}

const Input: React.ForwardRefExoticComponent<InputProps> & {
    Password: typeof AntInput.Password;
    TextArea: typeof AntInput.TextArea;
    Group: typeof AntInput.Group;
    Search: typeof AntInput.Search;
} = React.forwardRef<any, any>(({ className, ...props }, ref: any) => (
    <AntInput {...props} className={`input ${className || ""}`} ref={ref} />
)) as any;

Input.TextArea = AntInput.TextArea;
Input.Password = AntInput.Password;
Input.Group = AntInput.Group;
Input.Search = AntInput.Search;

export default Input;
