import AntRow, { RowProps as AntRowProps } from "antd/lib/row";
import { Gutter as AntGutter } from "antd/es/grid/row";

export interface RowProps extends AntRowProps {}
export type GutterProps = AntGutter;

const Row = (props: RowProps) => {
    return <AntRow {...props} />;
};

export default Row;
