import { Table, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { searcOpenVisitorLogs, selectAdminLoading, selectAdminVisitorLogs, downloadVisitorsLog, selectAdminVisitorLogsMeta, selectAdminVisitorLogsLinks, selectAdminDownloading } from "../../store/slices/admin";
import { IVisitorLog } from "../../store/slices/app";
import Button from "../../ui/Button/Button";
import { DATE_FORMAT, DEFAULT_ITEMS_PER_PAGE, DEFAULT_RANGE_PICKER, DEFAULT_TIMEZONE } from "../../utils/config";
const { RangePicker } = DatePicker;
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getProjects } from "../../store/slices/user";

dayjs.extend(utc)
dayjs.extend(timezone)
export interface IVisitorTableFilters {
    page: number;
    step: number;
    only_open: boolean;
    start_at?: string;
    finish_at?: string;
}

dayjs.extend(utc)
dayjs.extend(timezone)

const defaultStartDateTime = dayjs().startOf('day')
const defaultFinishDateTime = dayjs().add(1, 'day').startOf('day')

function VisitorTable() {

    const [filters, setFilters] = useState<IVisitorTableFilters>({
        page: 1,
        step: DEFAULT_ITEMS_PER_PAGE,
        only_open: false,
        start_at: defaultStartDateTime.format(),
        finish_at: defaultFinishDateTime.format(),
    })
    const dispatch = useDispatch<AppDispatch>();
    const openVisitorLogs = useSelector(selectAdminVisitorLogs);
    const openVisitorLogsMeta = useSelector(selectAdminVisitorLogsMeta);
    const loading = useSelector(selectAdminLoading);
    const downloading = useSelector(selectAdminDownloading);
    const projects = useSelector(getProjects);

    useEffect(() => {
        refresh()
    }, [filters])

    const cleanFilters = () => {
        let clean = {}
        Object.keys(filters)?.forEach((k: string) => {
            if (filters[k as keyof IVisitorTableFilters] !== undefined) {
                clean = {
                    ...clean,
                    [k]: filters[k as keyof IVisitorTableFilters]
                }
            }
        })
        return clean
    }

    const refresh = () => {
        dispatch(searcOpenVisitorLogs(cleanFilters()))
    }

    const download = () => {
        dispatch(downloadVisitorsLog(cleanFilters()))
    }

    const toggleOpenFilter = () => {
        setFilters((filters: IVisitorTableFilters) => {
            return {
                ...filters,
                only_open: !filters.only_open
            }
        })
    }

    const onPageChange = (page: number, pageSize: number) => {
        setFilters((filters: IVisitorTableFilters) => {
            return {
                ...filters,
                page: page,
                step: pageSize
            }
        })
    }

    const onProjectChange = (projectId?: number) => {
        setFilters((filters: IVisitorTableFilters) => {
            return {
                ...filters,
                project_id: projectId
            }
        })
    }

    const onDateRangeChange = (e: any | undefined) => {
        setFilters((filters: IVisitorTableFilters) => {
            return {
                ...filters,
                start_at: e ? e[0]?.format() : undefined,
                finish_at: e ? e[1]?.format() : undefined,
            }
        })
    }

    const getDataSource = () => {
        return openVisitorLogs?.map((log: IVisitorLog) => {
            const momentIn = dayjs.utc(log.in_at).tz(DEFAULT_TIMEZONE)
            const momentOut = dayjs.utc(log.out_at).tz(DEFAULT_TIMEZONE)
            const durationMilliSeconds = momentOut.diff(momentIn, 'milliseconds')
            const formattedDuration = dayjs(durationMilliSeconds).utc().format("HH:mm:ss")
            return {
                key: log.id,
                name: `${log.visitor.firstname} ${log.visitor.lastname}`,
                company: log.visitor.company,
                referrer: `${log.referrer.firstname} ${log.referrer.lastname}`,
                location: `${log.project.title}`,
                in_at: log.in_at ? momentIn.format(DATE_FORMAT) : "-",
                out_at: log.out_at ? momentOut.format(DATE_FORMAT) : "-",
                duration: log.out_at ? formattedDuration : "-",
            }
        })
    }

    const columns = [
        {
            title: 'Visitor',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Referrer',
            dataIndex: 'referrer',
            key: 'referrer',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'In @',
            dataIndex: 'in_at',
            key: 'in_at',
        },
        {
            title: 'Out @',
            dataIndex: 'out_at',
            key: 'out_at',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
    ];

    const getLocationOptions = () => projects?.map((item: any) => {
        return {
            value: item.id,
            label: `${item.title}`,
        }
    })

    return (
        <>
            <Button onClick={refresh} shape="round" type="primary" disabled={loading} loading={loading}>Refresh</Button>
            <Button onClick={download} shape="round" type="primary" disabled={loading || downloading} loading={downloading}>Download</Button>
            <Button onClick={toggleOpenFilter} shape="round" type="primary" disabled={loading}>{
                !filters.only_open ? "Currently Inside" : "Remove filter"
            }</Button>
            <RangePicker
                defaultValue={[defaultStartDateTime, defaultFinishDateTime]}
                format={DEFAULT_RANGE_PICKER} onChange={onDateRangeChange}
            />
            {/* getProjects */}
            {
                projects?.length > 1 ? (
                    <div style={{ display: "inline-block" }}>
                        <Select
                            placeholder="Filter by location"
                            data-testid="registration-form-item"
                            optionFilterProp="children"
                            filterOption={(input, option: any) => {
                                const label = "" + option?.label;
                                return label.toLowerCase().includes(input.toLowerCase())
                            }}
                            // onSearch={fetchOpenVisitorLogs}
                            allowClear
                            showSearch
                            options={getLocationOptions()}
                            onChange={(e) => onProjectChange(e)}
                        />
                    </div>
                ) : null
            }
            <Table
                dataSource={getDataSource()}
                columns={columns}
                pagination={{
                    total: openVisitorLogsMeta?.total,
                    current: filters.page,
                    pageSize: filters.step,
                    onChange: onPageChange,
                    showSizeChanger: true,
                    pageSizeOptions: [50, 100, 250]
                }}
            />
        </>
    )

}


export default VisitorTable