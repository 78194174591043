import { Alert, Drawer, Modal, Select } from 'antd';
import { Space } from 'antd/lib';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import VisitorInForm, { IVisitorInFormProps } from '../forms/VisitorInForm/VisitorInForm';
import VisitorOutForm, { IVisitorOutFormProps } from '../forms/VisitorOutForm/VisitorOutForm';
import { AppDispatch } from '../store';
import { resetOpenVisitorLogs, resetReferrersList, resetVisitors } from '../store/slices/app';
import { IModeType, INewVisitorType, MODE_IN, MODE_OUT, selectPublic, setCompleted, setIn, setMode, setNewVisitor, setOut } from '../store/slices/public';
import { getProjectId, getProjects, logout, setProjectId, verifyPassword } from '../store/slices/user';
import Button from '../ui/Button/Button';
import Input from '../ui/Input/Input';
import classNames from 'classnames';

function PublicPage() {

    const [cachedInForm, setCachedInForm] = useState<IVisitorInFormProps | undefined>(undefined);
    const [cachedOutForm, setCachedOutForm] = useState<IVisitorOutFormProps | undefined>(undefined);
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [openModalButtonVisible, setOpenModalButtonVisible] = useState<boolean>(false)
    const [selectedProjectId, setSelectedProjectId] = useState<string>()
    const [password, setPassword] = useState<string>("")
    const [modalError, setModalError] = useState<string>("")
    const [privacyDrawerOpen, setPrivacyDrawerOpen] = useState<boolean>(false)
    const dispatch = useDispatch<AppDispatch>();
    const { loading, mode, newVisitor, completed, error } = useSelector(selectPublic);
    const userProjects = useSelector(getProjects);
    const projectId = useSelector(getProjectId);

    useEffect(() => {
        if (userProjects?.length > 1) {
            setOpenModalButtonVisible(true)
        }
        else {
            setOpenModalButtonVisible(false)
        }
    }, [userProjects]);

    useEffect(() => {
        if (!projectId && userProjects?.length > 1) {
            openModal()
        }
        setSelectedProjectId(projectId)
    }, [projectId, userProjects])

    const openModal = () => {
        setModalOpen(true)
    }
    const closeModal = () => {
        setModalOpen(false)
        setSelectedProjectId(projectId)
        setPassword("")
        setModalError("")
    }

    useEffect(() => {
        switch (mode) {
            case MODE_IN:
            case MODE_OUT:
                scheduelReset()
                break
        }
    }, [completed])

    const scheduelReset = () => {
        window.setTimeout(() => {
            setCachedInForm(undefined)
            setCachedOutForm(undefined)
            dispatch(setMode(undefined))
            dispatch(setNewVisitor(undefined))
            dispatch(setCompleted(false))
            dispatch(resetReferrersList())
            dispatch(resetOpenVisitorLogs())
            dispatch(resetVisitors())
        }, 2000)
    }

    const registerVisitorIn = (values: any) => {
        setCachedInForm(values);
        dispatch(setIn(values));
    }
    const registerVisitorOut = (values: any) => {
        setCachedOutForm(values);
        dispatch(setOut(values));
    }

    const setUserMode = (mode: IModeType) => {
        dispatch(setMode(mode))
    }

    const setUserIsNewVisitor = (mode: INewVisitorType) => {
        setCachedInForm(undefined)
        dispatch(setNewVisitor(mode))
    }

    const resetMode = () => {
        setCachedInForm(undefined)
        setCachedOutForm(undefined)
        setUserMode(undefined)
        dispatch(setNewVisitor(undefined))
    }

    const setMOdeIn = () => {
        setUserMode(MODE_IN)
    }

    const setMOdeOut = () => {
        setUserMode(MODE_OUT)
    }

    const onOpenPrivacyDrawer = () => {
        setPrivacyDrawerOpen(true)
    }

    const onClosePrivacyDrawer = () => {
        setPrivacyDrawerOpen(false)
    }

    const getFormFooter = () => {
        return (
            <div className={classNames('form-footer', {
                'big': newVisitor === undefined
            })}>
                <div className='form-footer__title'>New Visitor?</div>
                <div className='form-footer__buttons'>
                    <Button onClick={() => setUserIsNewVisitor(true)} type="primary" shape='round' size="large">Yes</Button>
                    <Button onClick={() => setUserIsNewVisitor(false)} type="primary" shape='round' size="large" danger>No</Button>
                </div>
            </div>
        )
    }

    const getLocationOptions = () => userProjects?.map((item: any) => {
        return {
            value: item.id,
            label: `${item.title}`,
        }
    })

    const onProjectChange = (e: string) => {
        setSelectedProjectId(e)
    }

    const onConfirmSelectedProject = () => {
        dispatch(verifyPassword({ password })).then((res: any) => {
            if (!res?.error) {
                dispatch(setProjectId(selectedProjectId))
                closeModal()
            }
            else {
                setModalError("Incorrect Password!")
            }
        }).catch(e => {

        })

    }

    const getVisitorInForm = () => {
        if (mode !== MODE_IN) {
            return null
        }
        switch (newVisitor) {
            case true:
            case false:
                return (
                    <div>
                        <div>
                            <VisitorInForm
                                handleSubmit={registerVisitorIn}
                                handlePrivacyButtonClicked={onOpenPrivacyDrawer}
                                values={cachedInForm}
                                errors={error}
                                newVisitor={newVisitor}
                                loading={loading}
                            />
                        </div>
                        {getFormFooter()}
                    </div>
                )
            default:
                return getFormFooter()
        }
    }

    const onLogoutClicked = () => {
        dispatch(logout({}))
    }

    const getVisitorOutForm = () => {
        if (mode !== MODE_OUT) {
            return null
        }
        return (
            <VisitorOutForm
                handleSubmit={registerVisitorOut}
                values={cachedOutForm}
                errors={error}
                loading={loading}
            />
        )
    }

    const getSelectLocationButtonText = () => {
        if (!projectId) {
            return "Select Location"
        }

        return userProjects?.find((project: any) => project.id === projectId)?.title
    }

    return (
        <>
            <header className="App-header">
                <div className="App-header__public-logout-btn">
                    {
                        openModalButtonVisible ? (
                            <Button onClick={openModal}>{getSelectLocationButtonText()}</Button>
                        ) : null
                    }
                    <Button onClick={onLogoutClicked}>Logout</Button>
                </div>
                {
                    projectId ? completed ? (
                        <div>Thank You, the requested action is successfully succeeded</div>
                    ) :
                        !mode ? (
                            <>
                                <div>I'm going:</div>
                                <div className='App-imgoing-buttons-container'>
                                    <a className='button-in' onClick={setMOdeIn}>IN</a>
                                    <a className='button-out' onClick={setMOdeOut}>OUT</a>
                                </div>
                            </>
                        ) : (
                            <>
                                {/* <Space align="baseline"> */}
                                {getVisitorInForm()}
                                {getVisitorOutForm()}
                                {/* </Space> */}
                                <div className='App-back-home-container'>
                                    <a onClick={resetMode}>Back to home</a>
                                </div>
                            </>
                        ) : null
                }
            </header>
            <Modal
                title="Select location"
                centered
                open={modalOpen}
                onOk={onConfirmSelectedProject}
                onCancel={closeModal}
                closable={false}
                destroyOnClose={true}
                maskClosable={false}
                okButtonProps={{
                    disabled: !selectedProjectId
                }}
                cancelButtonProps={{
                    disabled: !selectedProjectId
                }}
            >
                <div>
                    <Select
                        placeholder="Filter by location"
                        data-testid="registration-form-item"
                        optionFilterProp="children"
                        filterOption={(input, option: any) => {
                            const label = "" + option?.label;
                            return label.toLowerCase().includes(input.toLowerCase())
                        }}
                        value={selectedProjectId}
                        showSearch
                        options={getLocationOptions()}
                        onChange={(e) => onProjectChange(e)}
                    />
                </div>
                {
                    modalError ? (
                        <div style={{ marginTop: "1rem" }}>
                            <Alert
                                message={modalError}
                                type="error"
                            />
                        </div>
                    ) : null
                }
                <div style={{ marginTop: "1rem" }}>
                    <Input.Password
                        placeholder="input password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
            </Modal>
            <Drawer 
                title="ADM Media Consulting SA" 
                placement="right"
                onClose={onClosePrivacyDrawer} 
                open={privacyDrawerOpen}
                className="privacy-drawer"
                width={"50%"}
            >
                <p>Gentile Visitatore,</p>
                <p>In relazione alla legge sulla protezione dei dati svizzera (LPD), desideriamo informarLa che la nostra azienda Svizzera si impegna a proteggere i dati personali dei nostri dipendenti e di coloro che interagiscono con noi, come anche dei nostri visitatori.</p>
                <p>Come previsto dalla LPD, ci impegniamo a trattare i dati personali dei nostri visitatori solo per gli scopi specifici per cui sono stati raccolti, come ad esempio per consentirLe l'accesso alle nostre strutture e per motivi di sicurezza. Inoltre, i dati personali verranno conservati solo per il tempo necessario a raggiungere gli scopi previsti o in conformità con le leggi applicabili.</p>
                <p>L'accesso ai dati personali sarà limitato ai dipendenti che necessitano di tali informazioni per svolgere le proprie mansioni e sarà utilizzato solo per gli scopi specifici per cui sono stati raccolti.</p>
            </Drawer>
        </>
    )
}


export default PublicPage