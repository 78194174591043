import React, { useEffect, useState } from 'react';
import { Button, Drawer, Dropdown, Layout, message, theme } from 'antd';
import VisitorTable from './VisitorsTable/VisitorsTable';
import type { MenuProps } from 'antd';
import { useSelector } from 'react-redux';
import { changePassword, logout, selectChangePassword, selectUser } from '../store/slices/user';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import ReferrerForm from '../forms/ReferrerForm/ReferrerForm';
import ReferrersList from './ReferrersList/ReferrersList';
import { closeReferrerFormModal, createProfileReferres, selectIsLoadingReferrer, selectReferrerModal, updateProfileReferres } from '../store/slices/app';
import ChangePasswordForm from '../forms/ChangePasswordForm/ChangePasswordForm';
const { Header, Content, Footer } = Layout;

const App: React.FC = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [messageApi, contextHolder] = message.useMessage();
    const openNotification = (message: string) => {
        messageApi.success(message);
      };

    const [referrerDrawerIsOpen, setReferrerDrawerIsOpen] = useState(false)
    const [changePasswordModalIsOpen, setChangePasswordModalIsOpen] = useState(false)

    const dispatch = useDispatch<AppDispatch>();
    const { identity } = useSelector(selectUser);
    const { referrerModalOpen, selectedReferrer } = useSelector(selectReferrerModal)
    const loadingReferrer = useSelector(selectIsLoadingReferrer)
    const { error, sent, loading } = useSelector(selectChangePassword)

    useEffect(() => {
        // console.log("Password has changed")
        if(sent && !error) {
            openNotification("Password has changed")
            setTimeout(() => {
                setChangePasswordModalIsOpen(false)
            }, 2000)
        }
    }, [error, sent])

    const onLogoutClicked = () => {
        dispatch(logout({}))
    }

    const onReferrerModalClose = () => {
        dispatch(closeReferrerFormModal())
    }

    const handleReferrerEdit = (values: any) => {
        if(selectedReferrer?.id) {
            // update
            dispatch(updateProfileReferres({
                ...selectedReferrer,
                ...values
            }))
        }
        else {
            // create
            dispatch(createProfileReferres(values))
        }
    }

    const openReferrerDrawer = () => {
        setReferrerDrawerIsOpen(true)
    }

    const onClosePrivacyDrawer = () => {
        setReferrerDrawerIsOpen(false)
    }

    const submitChangePassword = (values: any) => {
        dispatch(changePassword(values))
    }

    const handleChangePasswordFormClose = () => {
        setChangePasswordModalIsOpen(false)
    }
    
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a onClick={() => setChangePasswordModalIsOpen(true)}>
                    Password
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a onClick={openReferrerDrawer}>
                    Referrers
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a onClick={onLogoutClicked}>
                    Logout
                </a>
            ),
        }
    ];
    return (
        <Layout>
            {contextHolder}
            <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
                <div
                    style={{
                        float: 'left',
                        width: 50,
                        height: 50,
                        margin: '8px 0 8px 0',
                        // backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        backgroundImage: 'url(/adm-logo.png)',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
                <div style={{
                    float: 'right',
                }}>
                    <Dropdown menu={{ items }} placement="bottomRight" arrow>
                        <Button>{identity?.name}</Button>
                    </Dropdown>
                </div>
            </Header>
            <Content className="site-layout" style={{ padding: '0 50px' }}>
                {/* <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>List</Breadcrumb.Item>
                    <Breadcrumb.Item>App</Breadcrumb.Item>
                </Breadcrumb> */}
                <div style={{ padding: 24, minHeight: 380, background: colorBgContainer }}>
                    <VisitorTable />
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>©2023 Created by ADM Media Consulting SA</Footer>
            <Drawer
                title="Edit referrers list" 
                placement="right"
                onClose={onClosePrivacyDrawer} 
                open={referrerDrawerIsOpen}
                width={"70%"}
            >
                <ReferrersList />
            </Drawer>
            <ReferrerForm 
                isModalOpen={referrerModalOpen} 
                handleCancel={onReferrerModalClose}
                values={selectedReferrer}
                handleSubmit={handleReferrerEdit}
                submitButtonDisabled={loadingReferrer}
                isModal
            />
            <ChangePasswordForm 
                isModalOpen={changePasswordModalIsOpen} 
                handleSubmit={submitChangePassword} 
                submitButtonDisabled={loading}
                handleCancel={handleChangePasswordFormClose}
                errors={error}
                isModal 
            />
        </Layout>
    );
};

export default App;