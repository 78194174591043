import { configureStore } from "@reduxjs/toolkit";
// import { createLogger } from "redux-logger";

import userReducer from "./slices/user";
// import episodesReducer from "./slices/episodes";
import appReducer from "./slices/app";
import modalsReducer from "./slices/modals";
import publicReducer from "./slices/public";
import adminReducer from "./slices/admin";
// import qaReducer from "./slices/qa";

// import appListener from "./listeners/appListeners"
// import { episodeAddedListener } from "./listeners/episodesListeners";
// import episodesListener from "./listeners/episodesListeners"

// const logger = createLogger({ collapsed: true });

const store = configureStore({
  reducer: {
    user: userReducer,
    // episodes: episodesReducer,
    app: appReducer,
    modals: modalsReducer,
    publicPage: publicReducer,
    adminPage: adminReducer,
    // player: playerReducer,
    // qa: qaReducer
  },
  // middleware: (getDefaultMiddleware) => {
  //     return getDefaultMiddleware()
  //         .concat(appListener.middleware)
  //     // .concat(episodeAddedListener.middleware)
  // },
  // middleware: (getDefaultMiddleware) => {
  //     let middleware = getDefaultMiddleware();
  //     console.log("middleware1", middleware)
  //     if (process.env.NODE_ENV === "development") {
  //         middleware.prepend(logger)
  //     }

  //     middleware.prepend(appListener.middleware)
  //     console.log("middleware2", middleware)
  //     return middleware;
  // }
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
