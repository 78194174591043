import "./App.scss";
import { ConfigProvider } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Homepage from "./components/Homepage";
import { Provider } from "react-redux";
import store from "./store";
import 'antd/dist/reset.css';
import { getToken } from "./utils/auth";
import { stickToken } from "./store/slices/user";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
]);

const token = getToken();
if (token) {
  store.dispatch(stickToken(token));
}

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // colorPrimary: "#00b96b",
          // colorTextBase: "#52c41a",
          borderRadius: 0,
          borderRadiusOuter: 0,
          borderRadiusXS: 0,
          borderRadiusSM: 0,
          borderRadiusLG: 0,
          fontSize: 20,
          controlHeight: 40,
        },
      }}
    >
      <div className="App">
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </div>
    </ConfigProvider>
  );
}

export default App;
