import { useEffect, useState } from "react";
import classNames from "classnames";

import "./LoginForm.scss";
import Input from "../../ui/Input/Input";
import Col from "../../ui/Col/Col";
import Row from "../../ui/Row/Row";
import Button from "../../ui/Button/Button";
import Form from "../Form";

const { Item } = Form;
const { Password } = Input;

export interface LoginFormProps {
    display?: "list" | "inline";
    emailOnly?: boolean;
    errors?: any;
    handleSubmit?: (values: any) => void;
    handleSubmitError?: (errors: any) => void;
    loading?: boolean;
    logo?: string;
    switchMode?: boolean;
    switchTexts?: string[];
    values?: {
        email?: string;
        password?: string;
    };
}

const WITH_PASSWORD = "WITH_PASSWORD";
const WITHOUT_PASSWORD = "WITHOUT_PASSWORD";

const LoginForm = ({
    display = "list",
    emailOnly,
    errors,
    handleSubmit,
    handleSubmitError,
    loading = false,
    logo,
    switchMode = false,
    switchTexts,
    values,
}: LoginFormProps) => {
    const [form] = Form.useForm();
    const [actualSwitchMode, setActualSwitchMode] = useState(
        emailOnly ? WITHOUT_PASSWORD : WITH_PASSWORD
    );

    const actualSwitchText =
        (switchTexts &&
            (actualSwitchMode === WITHOUT_PASSWORD
                ? switchTexts[0]
                : switchTexts[1])) ||
        "";

    const handleSwitchMode = () => {
        setActualSwitchMode(
            actualSwitchMode === WITHOUT_PASSWORD
                ? WITH_PASSWORD
                : WITHOUT_PASSWORD
        );
    };

    const switchHtml = (
        <Col span={24}>
            <span
                role="presentation"
                data-testid="login-form-switch"
                onClick={handleSwitchMode}
                className="login-form__switch"
            >
                {actualSwitchText}
            </span>
        </Col>
    );

    const emailCol =
        display === "list" || actualSwitchMode === WITH_PASSWORD ? 24 : 16;
    const buttonCol = display === "list" ? 24 : 8;
    const emailInputClassNames = classNames("input", {
        "input--with-box-shadow":
            display === "inline" && actualSwitchMode === WITHOUT_PASSWORD,
    });

    useEffect(() => {
        if (values && form) {
            form.setFieldsValue(values);
        }
    }, [values]);

    useEffect(() => {
        if (errors && form) {
            const formattedErrors: any = [];

            Object.keys(errors).forEach((key: string) => {
                formattedErrors.push({
                    name: key,
                    errors: [errors[key]],
                });
            });

            form.setFields(formattedErrors);
        }
    }, [errors]);

    return (
        <header className="App-header">
            <div className="login-form">
                {logo && (
                    <Row>
                        <Col span={24} className="login-form__logo">
                            <img src={logo} alt="Logo" />
                        </Col>
                    </Row>
                )}
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    onFinishFailed={handleSubmitError}
                >
                    <Row gutter={16}>
                        <Col span={emailCol}>
                            <Item
                                data-testid="login-form-email"
                                name="email"
                                className="login-form__email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please insert your email!",
                                    },
                                ]}
                            >
                                <Input
                                    className={emailInputClassNames}
                                    placeholder="Email"
                                    size={"large"}
                                />
                            </Item>
                        </Col>

                        {actualSwitchMode === WITH_PASSWORD && (
                            <Col span={24}>
                                <Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please insert your password!",
                                        },
                                    ]}
                                >
                                    <Password
                                        data-testid="login-form-password"
                                        className="input"
                                        placeholder="Password"
                                        size={"large"}
                                    />
                                </Item>
                            </Col>
                        )}

                        {((switchMode && display === "list") ||
                            actualSwitchMode === WITH_PASSWORD) &&
                            switchHtml}
                        <Col span={buttonCol} className="login-form__button">
                            <Item>
                                <Button
                                    block
                                    size={"large"}
                                    loading={loading}
                                    data-testid="login-form-button"
                                    className="button"
                                    htmlType="submit"
                                    type="primary"
                                >
                                    Login
                                </Button>
                            </Item>
                        </Col>

                        {switchMode &&
                            display === "inline" &&
                            actualSwitchMode !== WITH_PASSWORD &&
                            switchHtml}
                    </Row>
                </Form>
            </div>
        </header>
    );
};

export default LoginForm;
