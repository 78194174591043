import { useEffect } from "react";

import "./OTPForm.scss";
import Input from "../../ui/Input/Input";
import Col from "../../ui/Col/Col";
import Row from "../../ui/Row/Row";
import Button from "../../ui/Button/Button";
import Form from "../Form";

const { Item } = Form;
const { Password } = Input;

export interface OTPFormProps {
    display?: "list" | "inline";
    emailOnly?: boolean;
    errors?: any;
    handleSubmit?: (values: any) => void;
    handleSubmitError?: (errors: any) => void;
    loading?: boolean;
    logo?: string;
    switchMode?: boolean;
    switchTexts?: string[];
    submitButtonText?: string;
    qrCodeSrc?: string;
    values?: {
        one_time_password?: string;
    };
}

const OTPForm = ({
    display = "list",
    errors,
    handleSubmit,
    handleSubmitError,
    loading = false,
    logo,
    values,
    submitButtonText,
    qrCodeSrc
}: OTPFormProps) => {
    const [form] = Form.useForm();

    const buttonCol = display === "list" ? 24 : 8;

    useEffect(() => {
        if (values && form) {
            form.setFieldsValue(values);
        }
    }, [values]);

    useEffect(() => {
        if (errors && form) {
            const formattedErrors: any = [];

            Object.keys(errors).forEach((key: string) => {
                formattedErrors.push({
                    name: key,
                    errors: [errors[key]],
                });
            });

            form.setFields(formattedErrors);
        }
    }, [errors]);

    return (
        <header className="App-header">
            <div className="login-form">
                {logo && (
                    <Row>
                        <Col span={24} className="login-form__logo">
                            <img src={logo} alt="Logo" />
                        </Col>
                    </Row>
                )}
                {
                    qrCodeSrc ? (
                        // <img src={qrCodeSrc} />
                        <div dangerouslySetInnerHTML={{
                            __html: qrCodeSrc
                        }} />
                    ): null
                }
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    onFinishFailed={handleSubmitError}
                >
                    <Row gutter={16}>



                            <Col span={24}>
                                <Item
                                    name="one_time_password"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please insert your OTP!",
                                        },
                                    ]}
                                >
                                    <Password
                                        data-testid="login-form-password"
                                        className="input"
                                        placeholder="One Time Password"
                                        size={"large"}
                                    />
                                </Item>
                            </Col>
                        <Col span={buttonCol} className="login-form__button">
                            <Item>
                                <Button
                                    block
                                    size={"large"}
                                    loading={loading}
                                    data-testid="login-form-button"
                                    className="button"
                                    htmlType="submit"
                                    type="primary"
                                >
                                    {submitButtonText || "Confirm"}
                                </Button>
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </header>
    );
};

export default OTPForm;
