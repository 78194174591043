import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";
// import { INode } from "../../components/layout/Layout";
import { RootState } from "../index";

import axios from "../../utils/client";
import { buildQuery } from "../../utils/buildQuery";
// import objectKeysToCamelCase from "../../utils/objectKeysToCamelCase";

export const ONLINE = "ONLINE";
export const OFFLINE = "OFFLINE";


// export type ILayout = {
//   // ui: INode | INode[];
//   isCompatibleVersion: boolean;
//   [key: string]: any;
// };

export type IReferrer = {
  id: number;
  firstname: string;
  lastname: string;
  company?: string | undefined;
  email?: string | undefined;
  role?: string | undefined;
  profile: any;
  project: any;
  projects: any;
}

export type IVisitor = {
  id: number;
  firstname: string;
  lastname: string;
  company?: string | undefined;
  email?: string | undefined;
  role?: string | undefined;
  profile: any;
  project: any;
}

export type IVisitorLog = {
  id: number,
  in_at?: string | undefined,
  out_at?: string | undefined,
  deleted_at?: string | undefined,
  visitor: IVisitor,
  referrer: any,
  profile: any,
  project: any,
  // "profile": {
  //     "id": "98bdf915-3230-467d-a8a4-408038bbb336",
  //     "name": "ADM",
  //     "projects": [
  //         {
  //             "id": "98bdfe1c-c8f5-41f9-8d36-f2157ea4929b",
  //             "title": null
  //         }
  //     ]
  // },
  // "project": {
  //     "id": "98bdfe1c-c8f5-41f9-8d36-f2157ea4929b",
  //     "title": null
  // }
}

export type IAppReducer = {
  domain: string | undefined;
  maintenance: boolean;
  error?: object;
  id: string | undefined;
  // layout: ILayout | undefined;
  loading: boolean;
  loadingReferrer: boolean;
  referrerModalOpen: boolean;
  selectedReferrer?: IReferrer | undefined;
  countryId?: string;
  langId?: string;
  tagManager?: string;
  timezone: string;
  network: typeof ONLINE | typeof OFFLINE;
  referrers?: IReferrer[] | undefined;
  visitors?: IVisitor[] | undefined;
  openVisitorLogs?: IVisitorLog[] | undefined;
  // TODO: Remove when is ready get events api request
};

const initialState: IAppReducer = {
  domain: undefined,
  maintenance: false,
  error: undefined,
  id: undefined,

  // layout: undefined,

  loading: false,
  loadingReferrer: false,
  countryId: undefined,
  langId: undefined,
  tagManager: undefined,
  timezone: "",
  network: ONLINE,
  referrers: [],
  visitors: [],
  openVisitorLogs: [],
  referrerModalOpen: false
};

// export const fetchLayouts = createAsyncThunk<ILayout>(
//   "app/fetchLayouts",
//   async (props: any, thunkApi) => {
//     const {
//       app: { domain, langId },
//     } = thunkApi.getState() as RootState;
//     try {
//       const res = await axios.get(
//         `/event/detect?domain=${domain}&lang_id=${langId}`
//       );
//       return res.data.data;
//     } catch (error) {
//       return thunkApi.rejectWithValue({
//         error,
//       });
//     }
//   }
// );

export const getProfileReferres = createAsyncThunk<IReferrer[], any | undefined>(
  "app/searchReferrers",
  async (props: any, thunkApi) => {
    const {
      user: { token, profileId }
    } = thunkApi.getState() as RootState;
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let query = ""
      if (props) {
        query = buildQuery(props)
      }
      const res = await axios.get(
        `/v1/${profileId}/referrers?${query}`,
        headers
      );
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue({
        error,
      });
    }
  }
);
export const createProfileReferres = createAsyncThunk<IReferrer, any | undefined>(
  "app/createProfileReferres",
  async (props: any, thunkApi) => {
    const {
      user: { token, profileId }
    } = thunkApi.getState() as RootState;
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `/v1/${profileId}/referrers`,
        props,
        headers
      );
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue({
        error,
      });
    }
  }
);

export const updateProfileReferres = createAsyncThunk<IReferrer, any | undefined>(
  "app/updateProfileReferres",
  async (props: any, thunkApi) => {
    const {
      user: { token, profileId }
    } = thunkApi.getState() as RootState;
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.put(
        `/v1/${profileId}/referrers/${props.id}`,
        props,
        headers
      );
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue({
        error,
      });
    }
  }
);
export const searchReferrers = createAsyncThunk<IReferrer[], any | undefined>(
  "app/searchReferrers",
  async (props: any, thunkApi) => {
    const {
      user: { token, profileId, projectId }
    } = thunkApi.getState() as RootState;
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let query = ""
      if (props) {
        query = buildQuery(props)
      }
      const res = await axios.get(
        `/v1/${profileId}/${projectId}/referrers?${query}`,
        headers
      );
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue({
        error,
      });
    }
  }
);

export const searcOpenVisitorLogs = createAsyncThunk<IVisitorLog[], any | undefined>(
  "app/searcOpenVisitorLogs",
  async (props: any, thunkApi) => {
    const {
      user: { token, profileId, projectId }
    } = thunkApi.getState() as RootState;
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let query = ""
      if (props) {
        query = buildQuery(props)
      }
      const res = await axios.get(
        `/v1/${profileId}/${projectId}/open?${query}`,
        headers
      );
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue({
        error,
      });
    }
  }
);

export const searcVisitors = createAsyncThunk<IVisitor[], any | undefined>(
  "app/searcVisitors",
  async (props: any, thunkApi) => {
    const {
      user: { token, profileId, projectId }
    } = thunkApi.getState() as RootState;
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let query = ""
      if (props) {
        query = buildQuery(props)
      }
      const res = await axios.get(
        `/v1/${profileId}/${projectId}/visitors?${query}`,
        headers
      );
      return res.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue({
        error,
      });
    }
  }
);


export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {

    changeNetworkState: (state, { payload }) => {
      state.network = payload;
    },
    changeAppLangId: (state, { payload }) => {
      state.langId = payload;
      // dispatch
    },
    changeAppCountryId: (state, { payload }) => {
      state.countryId = payload;
    },
    setReferrersList: (state, { payload }) => {
      state.referrers = payload;
    },
    setOpenVisitorLogs: (state, { payload }) => {
      state.referrers = payload;
    },
    resetReferrersList: (state) => {
      state.referrers = [];
    },
    resetOpenVisitorLogs: (state) => {
      state.openVisitorLogs = [];
    },
    resetVisitors: (state) => {
      state.visitors = [];
    },
    openReferrerFormModal: (state) => {
      state.referrerModalOpen = true;
    },
    closeReferrerFormModal: (state) => {
      state.referrerModalOpen = false;
    },
    updateSelectedReferrer: (state, { payload }) => {
      state.selectedReferrer = payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchLayouts.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(fetchLayouts.fulfilled, (state, { payload }) => {
    //   state.loading = false;

    //   // if (payload.legals) {
    //   state.layout = {
    //     ui: payload.theme.config,
    //     isCompatibleVersion: payload.theme.version === 2,
    //     registrationForm: {
    //       id: payload.registration_form,
    //       hasInternal: payload.has_internal,
    //     },
    //     theme: {
    //       ...objectKeysToCamelCase(payload.styles),
    //       footerBackgroundImage:
    //         payload.media.footer_background_desktop?.url || undefined,
    //     },
    //   };
    //   // }

    //   state.maintenance = payload.maintenance;
    //   state.id = payload.id;
    //   state.langId = payload.default_language;
    //   state.timezone = payload.timezone;
    //   if (payload.tagmanager) {
    //     state.tagManager = payload.tagmanager;
    //   }

    // });

    // builder.addCase(fetchLayouts.rejected, (state, { error }) => {
    //   state.loading = false;
    //   state.error = error;
    // });

    builder.addCase(searchReferrers.pending, (state) => {
      state.loading = false;
    });

    builder.addCase(searchReferrers.fulfilled, (state, { payload }) => {
      state.referrers = payload;
    })

    builder.addCase(searchReferrers.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
    builder.addCase(searcOpenVisitorLogs.pending, (state) => {
      state.loading = false;
    });

    builder.addCase(searcOpenVisitorLogs.fulfilled, (state, { payload }) => {
      state.openVisitorLogs = payload;
    })

    builder.addCase(searcOpenVisitorLogs.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });

    builder.addCase(searcVisitors.pending, (state) => {
      state.loading = false;
    });

    builder.addCase(searcVisitors.fulfilled, (state, { payload }) => {
      state.visitors = payload;
    })

    builder.addCase(searcVisitors.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });

    builder.addCase(updateProfileReferres.pending, (state) => {
      state.loadingReferrer = true;
    });
    builder.addCase(updateProfileReferres.fulfilled, (state, { payload }) => {
      state.loadingReferrer = false;
      state.referrers = state.referrers?.map(item => {
        return payload.id === item.id ? payload : item
      })
    });
    builder.addCase(updateProfileReferres.rejected, (state) => {
      state.loadingReferrer = false;
    });
    builder.addCase(createProfileReferres.pending, (state) => {
      state.loadingReferrer = true;
    });
    builder.addCase(createProfileReferres.fulfilled, (state, { payload }) => {
      state.loadingReferrer = false;
      let referrers = [...state.referrers || []]
      referrers.unshift(payload) || [];
      state.referrers = referrers
    });
    builder.addCase(createProfileReferres.rejected, (state) => {
      state.loadingReferrer = false;
    });
  },
});

const selectState = (state: RootState) => state;
const selectSelf = createDraftSafeSelector(selectState, ({ app }) => app);
export const selectIsLoadingLayout = createDraftSafeSelector(
  selectSelf,
  ({ loading }) => loading
);
export const selectIsLoadingReferrer = createDraftSafeSelector(
  selectSelf,
  ({ loadingReferrer }) => loadingReferrer
);
export const selectAppId = createDraftSafeSelector(selectSelf, ({ id }) => id);
export const selectLocalStorageEpisodeKey = createDraftSafeSelector(
  selectSelf,
  ({ id }) => `current-episode-id-${id}`
);
export const selectTagManagerId = createDraftSafeSelector(
  selectSelf,
  ({ tagManager }) => tagManager
);
export const selectMaintenance = createDraftSafeSelector(
  selectSelf,
  ({ maintenance }) => maintenance
);
export const selectTimezone = createDraftSafeSelector(
  selectSelf,
  ({ timezone }) => timezone
);
export const selectNetworkStatus = createDraftSafeSelector(
  selectSelf,
  ({ network }) => network
);
export const selectReferrers = createDraftSafeSelector(
  selectSelf,
  ({ referrers }) => referrers
);
export const selectOpenVisitorLogs = createDraftSafeSelector(
  selectSelf,
  ({ openVisitorLogs }) => openVisitorLogs
);
export const selectVisitors = createDraftSafeSelector(
  selectSelf,
  ({ visitors }) => visitors
);
export const selectReferrerModal = createDraftSafeSelector(
  selectSelf,
  ({ referrerModalOpen, selectedReferrer }) => ({referrerModalOpen, selectedReferrer})
);

// export const selectLayout = createDraftSafeSelector(
//   selectSelf,
//   ({ layout }) => layout
// );
// export const selectHomeLayout = createDraftSafeSelector(
//   selectLayout,
//   (layout) => layout?.ui
// );
// export const selectLayoutTheme = createDraftSafeSelector(
//   selectLayout,
//   (layout: any) => layout?.theme || {}
// );
// export const selectIsCompatibleThemeVersion = createDraftSafeSelector(
//   selectLayout,
//   (layout: any) => layout?.isCompatibleVersion
// );
// export const selectRegistrationFormId = createDraftSafeSelector(
//   selectLayout,
//   (layout) => layout?.registrationForm.id
// );

// export const selectRegistrationFormHasInternal = createDraftSafeSelector(
//   selectLayout,
//   (layout) => layout?.registrationForm.hasInternal
// );

export const {
  changeNetworkState,
  changeAppLangId,
  changeAppCountryId,
  setReferrersList,
  resetReferrersList,
  resetOpenVisitorLogs,
  resetVisitors,
  openReferrerFormModal,
  closeReferrerFormModal,
  updateSelectedReferrer
} = appSlice.actions;

export default appSlice.reducer;
