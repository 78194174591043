import { useEffect, useState } from "react";
import { completeLogin, getMe, login, selectIsLogging, selectUser } from "../store/slices/user";
import Spin from "../ui/Spin/Spin";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store"
import LoginForm from "../forms/LoginForm/LoginForm";
import { Space } from "antd";
import { closeModal } from "../store/slices/modals";
import OTPForm from "../forms/OTPForm/OTPForm";

interface ICachedForm {
    email?: string;
    password?: string;
    one_time_password?: string;
}

const WithAuth = (Component: any) => (props: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const { token, identity, loading, error, qrCodeSrc, requireOTP } = useSelector(selectUser);
    const userIsLogging = useSelector(selectIsLogging);

    const [authenticated, setAuthenticated] = useState<any>(undefined);
    const [cachedForm, cacheForm] = useState<ICachedForm | undefined>(undefined);
    const [activeSession, setActiveSession] = useState<any>(undefined);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (token && !identity && !requireOTP) {
            dispatch(getMe());
        }
    }, [token]);

    useEffect(() => {
        if (identity) {
            setAuthenticated(true)
        }
    }, [identity]);

    useEffect(() => {
        if (error && error.code === 401) {
            setAuthenticated(false)
        }
    }, [error]);

    const handleLogin = (values: any) => {
        cacheForm(values);
        dispatch(login(values)).then(({ payload }) => {
            if (payload.code === 403) {
                setActiveSession(payload);
            }

            if (payload.code === 422) {
                const field = payload.body.message?.search("Password") !== -1 ? "password" : "email";
                setErrors({
                    [field]: payload.body.message || "Your login credentials are invalid"
                });
            }

            if (!payload.code) {
                dispatch(closeModal());
            }
        });
    };

    const handleCompleteLogin = (values: any) => {
        cacheForm(values);
        dispatch(completeLogin(values)).then(({ payload }) => {
            if (payload.code === 403) {
                setActiveSession(payload);
            }

            if (payload.code === 422) {
                const field = payload.body.message?.search("Password") !== -1 ? "password" : "email";
                setErrors({
                    [field]: payload.body.message || "Your login credentials are invalid"
                });
            }

            if (!payload.code) {
                dispatch(closeModal());
            }
        });
    };

    return loading ? (
        <Spin fullPage spinning={loading}>
            <div />
        </Spin>
    ) : authenticated ? (
        <Component {...props} />
    ) : (
        <div className="login-page">
            <Space align="baseline">
                {
                    requireOTP ? (
                        <>
                            <OTPForm
                                handleSubmit={handleCompleteLogin}
                                loading={userIsLogging}
                                values={cachedForm}
                                submitButtonText={"Confirm"}
                                errors={errors}
                                qrCodeSrc={qrCodeSrc}
                            />
                        </>
                    ) : (
                        <LoginForm
                        handleSubmit={handleLogin}
                        loading={userIsLogging}
                        values={cachedForm}
                        // emailOnly
                        errors={errors}
                    // switchMode
                    // switchTexts={[switchToChairmanText, switchToUserText]}
                    />
                    )
                }

            </Space>
        </div>
    );
};

export default WithAuth;
